<template>
  <div class="container">
    <header class="jumbotron">
      <h3>
        <strong>{{currentUser.username}}</strong> Profile
      </h3>
    </header>
    <p>
      <strong>Token:</strong>
      {{currentUser.token.substring(0, 20)}} ...
      {{currentUser.token.substr(currentUser.token.length - 20)}}
    </p>
    <p>
      Theme:
      <Dropdown
        v-model="selectedTheme"
        :options="themeOptions"
        optionLabel="name"
        :placeholder="themeID"
        @change="updateTheme"
        />
    </p>
  </div>
</template>

<script>
export default {
  name: 'UserHome',

  data() {
    return {
      themeOptions: [
        {
          name: 'palette1',
          code: 'palette1',
        },
        {
          name: 'palette2',
          code: 'palette2',
        },
        {
          name: 'palette3',
          code: 'palette3',
        },
        {
          name: 'palette4',
          code: 'palette4',
        },
        {
          name: 'palette5',
          code: 'palette5',
        },
        {
          name: 'palette6',
          code: 'palette6',
        },
        {
          name: 'palette7',
          code: 'palette7',
        },
        {
          name: 'palette8',
          code: 'palette8',
        },
        {
          name: 'palette9',
          code: 'palette9',
        },
        {
          name: 'palette10',
          code: 'palette10',
        },
      ],
      selectedTheme: this.themeID
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.authStore.user
    },
    themeID() {
      return this.$store.state.userPrefsStore.themeID
    }
  },
  methods: {
    updateTheme(event) {
      this.$store.commit('userPrefsStore/setTheme', event.value.code)
    }
  },

  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
}
</script>
